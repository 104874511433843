export const types = {
  'SET_REFRESH_PO_REQUEST_STATUS': 'SET_REFRESH_PO_REQUEST_STATUS',
  'SET_PO_REQUEST': 'SET_PO_REQUEST',
  'SET_PO_REQUEST_LOADING': 'SET_PO_REQUEST_LOADING',
  'SET_PO_REQUEST_STREAM': 'SET_PO_REQUEST_STREAM',
  'SET_OPEN_PO_REQUEST_LOADING': 'SET_OPEN_PO_REQUEST_LOADING',
  'SET_OPEN_PO_REQUEST': 'SET_OPEN_PO_REQUEST',
  'SET_OPEN_PO_REQUEST_OBJECT': 'SET_OPEN_PO_REQUEST_OBJECT',
  'SET_CLEAR_OPEN_PO_REQUEST_OBJECT': 'SET_CLEAR_OPEN_PO_REQUEST_OBJECT',
  'SET_PO_REQUEST_NOTIFICATION': 'SET_PO_REQUEST_NOTIFICATION',
  'SET_PREVIOUS_PO_REQUEST_CURSOR': 'SET_PREVIOUS_PO_REQUEST_CURSOR',
  'SET_NEXT_PO_REQUEST_CURSOR': 'SET_NEXT_PO_REQUEST_CURSOR',
  'SET_PO_REQUEST_TOTAL': 'SET_PO_REQUEST_TOTAL',
  'SET_CURRENT_PO_REQUEST_PAGE_INDEX': 'SET_CURRENT_PO_REQUEST_PAGE_INDEX',
  'SET_CURRENT_PO_REQUEST_PAGE_SIZE': 'SET_CURRENT_PO_REQUEST_PAGE_SIZE',
  'SET_PO_REQUEST_SEARCH_KEYWORD': 'SET_PO_REQUEST_SEARCH_KEYWORD',
  'GET_PO_REQUEST_DETAIL': 'GET_PO_REQUEST_DETAIL',
  'SET_IS_HOME_OCCUPIED': 'SET_IS_HOME_OCCUPIED' 
};
