import { Reducer } from 'redux';
import { types } from '../actions/subscription/subscription.types';


export interface SubscriptionState {
  error: string | undefined;
  loading: boolean;
  subscription: {};
}

const initialSubscriptionState: SubscriptionState = {
  'error': undefined,
  'loading': false,
  'subscription': {}

};

export const SubscriptionReducer: Reducer<any> = (state = initialSubscriptionState, action) => {
  switch (action.type) {
    case types.SET_SUBSCRIPTION:
      return {
        ...state,
        'subscription': action.payload,
        error: ''
      };
    case types.SET_SUBSCRIPTION_LOADING:
      return {
        ...state,
        'loading': action.payload,
      };
    case types.CLEAR_SUBSCRIPTION:
      return {
        ...state,
        'subscription': {},
        error: ''
      };
    case types.SET_SUBSCRIPTION_ERROR:
      return {
        ...state,
        'subscription': {},
        'error': action.payload,
      }
    default:
      break;
  }
  return state;
};
